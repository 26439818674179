import { firebase } from "@firebase/app";
import "firebase/firestore";
import store from "./store";
import "@firebase/auth";


const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDwlronY8NsvurFSF-SdnRwfSmYPpK5VHU",
    authDomain: "pandemic-2c65b.firebaseapp.com",
    databaseURL: "https://pandemic-2c65b.firebaseio.com",
    projectId: "pandemic-2c65b",
    storageBucket: "pandemic-2c65b.appspot.com",
    messagingSenderId: "594396829350",
    appId: "1:594396829350:web:e369885b1a02f4f8bd4667",
    measurementId: "G-P48Z8QQK5L"
  });
  
  firebase.auth().onAuthStateChanged(user => {
    //console.log('olha a authenticação minha gente' + user);
    store.dispatch("fetchUser", user);
  });
  
  firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

  export const db = firebaseApp.firestore();
  export const fb = firebase;