<template>
  <v-card text>
    <v-snackbar v-model="snackbar.visible" top right>
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.visible = false">Fechar</v-btn>
    </v-snackbar>
    <v-card-title>Adicionar Doença</v-card-title>
    <v-card-text>
      <v-row v-if="loading" justify="center" align="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
      <v-list v-if="!loading">
        <v-form ref="form" lazy-validation>
          <v-text-field v-model="disease.name" :counter="40" label="Nome" required></v-text-field>
          <v-text-field v-model="disease.scientific" :counter="40" label="Nome científico" required></v-text-field>
          <v-text-field v-model="disease.type" :counter="40" label="Tipo" required></v-text-field>
          <h3>Images</h3>
          <v-list v-if="!loading">
            <v-list-group v-for="(images, i) in disease.images" :key="i" no-action>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-text-field
                    v-model="images.text"
                    flat
                    hide-details
                    background-color="transparent"
                    label="Imagem"

                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="deleteItem(images, disease.images)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-group>

            <v-list-item @click="addItem(disease.images)">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Adicionar imagem</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h3>Sintomas</h3>
          <v-list v-if="!loading">
            <v-list-group v-for="(symptoms, i) in disease.symptoms" :key="i" no-action>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-text-field
                    v-model="symptoms.key"
                    flat
                    hide-details
                    background-color="transparent"
                    label="Parte da planta"
                  ></v-text-field>
                  <v-text-field
                    v-model="symptoms.text"
                    flat
                    hide-details
                    background-color="transparent"
                    label="Sintoma"

                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="deleteItem(symptoms, disease.symptoms)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-group>

            <v-list-item @click="addItem(disease.symptoms)">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Adicionar sintoma</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h3>Sobre a doença</h3>
          <v-list v-if="!loading">
            <v-list-group v-for="(about, i) in disease.about" :key="i" no-action>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-text-field
                    v-model="about.key"
                    flat
                    hide-details
                    background-color="transparent"
                    label="Pergunta"

                  ></v-text-field>
                  <v-text-field
                    v-model="about.text"
                    flat
                    hide-details
                    background-color="transparent"
                    label="Informação"

                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="deleteItem(about, disease.about)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-group>

            <v-list-item @click="addItem(disease.about)">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Adicionar informação</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h3>Prevenção</h3>
          <v-list v-if="!loading">
            <v-list-group v-for="(prevention, i) in disease.prevention" :key="i" no-action>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-text-field
                    v-model="prevention.text"
                    solo
                    flat
                    hide-details
                    background-color="transparent"
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="deleteItem(prevention, disease.prevention)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-group>

            <v-list-item @click="addItem(disease.prevention)">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Adicionar prevenção</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h3>Tratamento</h3>
          <v-list v-if="!loading">
            <v-list-group v-for="(treatment, i) in disease.treatment" :key="i" no-action>
              <template v-slot:activator>
                <v-list-item-title>
                  <v-text-field
                    v-model="treatment.text"
                    solo
                    flat
                    hide-details
                    background-color="transparent"
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="deleteItem(treatment, disease.treatment)">
                    <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-group>

            <v-list-item @click="addItem(disease.treatment)">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Adicionar tratamento</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          

          <v-container class="py-0">
            <v-row align="center" justify="start">
              <h3>Hospedeiros</h3>

              <v-col v-for="(selection, i) in disease.hosts" :key="selection" class="shrink">
                <v-chip :disabled="loading" close @click:close="disease.hosts.splice(i, 1)">
                  <v-icon left v-text="selection.icon"></v-icon>
                  {{ selection.name }}
                </v-chip>
              </v-col>

              <v-col v-if="!allSelected" cols="12">
                <v-text-field
                  ref="search"
                  v-model="search"
                  full-width
                  hide-details
                  label="Pesquisar hosts"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-divider v-if="!allSelected"></v-divider>

          <v-list>
            <template v-for="item in categories">
              <v-list-item
                v-if="!disease.hosts.includes(item)"
                :key="item"
                :disabled="loading"
                @click="disease.hosts.push(item)"
              >
                <v-list-item-avatar>
                  <v-icon :disabled="loading" v-text="item.icon"></v-icon>
                </v-list-item-avatar>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>

          <v-divider></v-divider>
        </v-form>
      </v-list>
      <v-row v-if="!loading">
        <v-spacer />
        <v-btn @click="save()" color="primary" class="mr-3">Salvar</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { db } from "@/firebase";

export default {
  data() {
    return {
      snackbar: {
        text: null,
        visible: false,
      },
      loading: false,
      plants: [],
      key: "",
      disease: {
        about: [],
        scientific: "",
        hosts: [],
        name: "",
        prevention: [],
        symptoms: [],
        type: "",
        treatment: [],
        url: "",
        images:[]
      },
      templatesQuerySnapshot: [],
      search: "",
      id: ''
    };
  },
  async created() {
    try {
      this.loading = true;
      const self = this;
      this.id = this.$route.params.disease
      if (this.$route.params.disease) {
        this.templatesQuerySnapshot = await db
          .collection("diseases")
          .doc(this.$route.params.disease)
          .get()
          .then((documentSnapshot) => {
            const disease = documentSnapshot.data();
            console.log(disease);
            this.disease = disease;
          });
      }
      this.templatesQuerySnapshot = await db
        .collection("plants")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            const plant = doc.data();
            self.plants.push({ name: plant.name });
            console.log("pegou ", doc.data());
            //console.log(doc.id, " => ", doc.data());
          });
        });

      this.loading = false;
    } catch (err) {
      this.snackbar.text = err.message;
      this.snackbar.visible = true;

      console.error(err);

      this.loading = false;
    }
  },
  computed: {
    allSelected() {
      return this.disease.hosts.length === this.plants.length;
    },
    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.plants;

      return this.plants.filter((item) => {
        const text = item.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.plant.diseases) {
        selections.push(selection);
      }

      return selections;
    },
  },
  watch: {
    selected() {
      this.search = "";
    },
  },
  methods: {
    deleteItem(item, parent) {
      parent.splice(parent.indexOf(item), 1);
    },
    addItem(parent) {
      const template = {
        text: "",
      };
      parent.push(template);
    },
    async save() {
      try {
        this.loading = true;
        if (this.id) {
          await db
            .collection("diseases")
            .doc(this.id)
            .set(this.disease);
        } else {
          await db.collection("diseases").add(this.disease);
        }

        //for (const template of this.templates) {
        //await db.collections(`plants`).set(template)
        //}

        this.snackbar.text = "Dados salvos com sucesso";
        this.snackbar.visible = true;

        this.loading = false;
      } catch (err) {
        this.snackbar.text = err.message;
        this.snackbar.visible = true;

        console.log(err);

        this.loading = false;
      }
    },
  },
};
</script>