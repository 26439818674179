<template>
  <v-container>
    <v-list subheader>
      <v-row>
        <v-col cols="3">
          <v-list-item>
            <v-card>
              <v-img
                :src="diagnostic.url"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
                width="200px"
              >
                <v-card-title v-text="diagnostic.referencePlant"></v-card-title>
              </v-img>

              <span v-if="diagnostic.geoReferência">
                <v-text-field label="Lat" v-model="diagnostic.geoReferência.latitude" readonly></v-text-field>
                <v-text-field label="Lng" v-model="diagnostic.geoReferência.longitude" readonly></v-text-field>
              </span>
              <div class="mt-2 text-left">Doença: {{ diagnostic.classificacao }}</div>
              <div class="mt-2 text-left">Planta: {{ diagnostic.referencePlant }}</div>
              <div class="mt-2 text-left">Nome: {{ user.nome }}</div>
              <div class="mt-2 text-left">Telefone: {{ user.phoneNumber }}</div>
            </v-card>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-subheader>Recent chat</v-subheader>

          <v-list-item v-for="(item, i) in messages" :key="i">
            <!--v-list-item-avatar>
        <v-img :src="item.avatar"></v-img>
            </v-list-item-avatar-->

            <v-list-item-content
              class="Message"
              :class="{myMessage: (item.idFrom == uid)?true:false}"
              v-if="item.type == 0"
            >
            <div class="mt-12">{{ item.content }}</div>
              
            </v-list-item-content>
            <v-list-item-content
              class="Message"
              :class="{myMessage: (item.idFrom == uid)?true:false}"
              v-else-if="item.type == 1"
            >
              <v-img style="max-height:300px; width:auto" contain :src="item.content"></v-img>
            </v-list-item-content>

            <!--v-list-item-icon>
        <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">chat_bubble</v-icon>
            </v-list-item-icon-->
          </v-list-item>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-if="diagnostic.status != 'closed'"
                v-model="message"
                label="Mensagem"
                placeholder="Mensagem..."
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="diagnostic.status != 'closed'"
                v-on:keyup.enter="enviar"
                @click="enviar()"
              >Enviar</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-btn v-if="diagnostic.status != 'closed'" @click="encerrar()">Encerrar</v-btn>
          <v-btn v-if="diagnostic.status == 'closed'" @click="ativar()">Reativar</v-btn>
        </v-col>
      </v-row>
    </v-list>
  </v-container>
</template>
<script>
import { db, fb } from "@/firebase";
//import store from "../store";
export default {
  data() {
    return {
      chatroom: {},
      messages: [],
      message: "",
      uid: "",
      user: {},
      diagnostic: {},
      coisa: true,
    };
  },
  methods: {
    async ativar() {
      //          const tempo = Date.now().toString()
      await db
        .collection("diagnostic")
        .doc(this.chatroom.diagnostic)
        .set({ status: "active" }, { merge: true });
      //console.log("encerrou?", this.chatroom.diagnostic);
      this.diagnostic.status = "";
      //this.message = ''
    },
    async encerrar() {
      //          const tempo = Date.now().toString()
      await db
        .collection("diagnostic")
        .doc(this.chatroom.diagnostic)
        .set({ status: "closed" }, { merge: true });
      console.log("encerrou?", this.chatroom.diagnostic);
      this.diagnostic.status = "closed";
      //this.message = ''
    },
    async enviar() {
      const tempo = Date.now().toString();
      const mensagem = {
        content: this.message,
        idFrom: this.uid,
        timestamp: tempo,
        type: 0,
      };
      await db
        .collection("messages")
        .doc(this.$route.params.id)
        .collection("messages")
        .doc(tempo)
        .set(mensagem);
      this.message = "";
    },
  },
  async created() {
    try {
      this.loading = true;
      await fb.getCurrentUser().then((result) => {
        //console.log('olha o coisa', result.uid)
        this.uid = result.uid;
      });

      await db
        .collection("messages")
        .doc(this.$route.params.id)
        .get()
        .then((document) => {
          this.chatroom = document.data();
        });
      var self = this;
      db.collection("messages")
        .doc(this.$route.params.id)
        .collection("messages")
        .onSnapshot(
          (querySnapshot) => {
            self.messages = [];
            querySnapshot.forEach(function (doc) {
              self.messages.push(doc.data());
            });
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
      await db
        .collection("diagnostic")
        .doc(this.chatroom.diagnostic)
        .get()
        .then((document) => {
          this.diagnostic = document.data();
        });
        await db
        .collection("users")
        .doc(this.diagnostic.userUid)
        .get()
        .then((document) => {
          this.user = document.data();
        });
      console.log(this.chatrooms);
      this.loading = false;
    } catch (err) {
      console.error(err);

      this.loading = false;
    }
  },
};
</script>

<style scoped>
.Message {
  text-align: left;
  float: left;
}
.myMessage {
  background-color: rgb(216, 213, 213);
  color: black;
  text-align: right;
}
</style>