<template>
  <v-row dense>
    <v-col v-for="diag in diagnostics" v-bind:key="diag.id">
      <v-card>
        <v-img
          :src="diag.url"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
          width="200px"
        >
          <v-card-title v-text="diag.referencePlant"></v-card-title>
        </v-img>

        <v-card-actions>
          <v-spacer></v-spacer>
          <span v-if="diag.geoReferência">
            <v-text-field label="Lat" v-model="diag.geoReferência.latitude" readonly></v-text-field>
            <v-text-field label="Lng" v-model="diag.geoReferência.longitude" readonly></v-text-field>
          </span>

          <v-btn icon @click="deleteItem(diag, diagnostics)">
            <v-icon color="red lighten-1">mdi-trash-can</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { db } from "@/firebase";

export default {
  data() {
    return {
      snackbar: {
        text: null,
        visible: false,
      },
      card: {
        title: "titulo",
        src: "sorce",
      },
      diagnostics: [],
      ids: [],
      templatesQuerySnapshot: [],
    };
  },
  methods: {
    async deleteItem(item, parent) {
      await db.collection("diagnostic").doc(item.id).delete();
      await db.collection("messages").doc(item.id).delete();

      parent.splice(parent.indexOf(item), 1);
    },
  },
  async created() {
    try {
      this.loading = true;

      await db
        .collection("diagnostic")
        .get()
        .then((documents) => {
          documents.docs.forEach((element) => {
            const ele = element.data();
            ele["id"] = element.id;
            ele["expand"] = false;
            //console.log(element)

            this.diagnostics.push(ele);
          });
        });

      //console.log('olha o tamanho ', this.diagnostics.length)

      //this.templatesQuerySnapshot =

      //this.templatesQuerySnapshot.forEach(documentSnapshot => {
      //const plant = { name: documentSnapshot.data().name, id: documentSnapshot.id };
      // console.log(documentSnapshot);
      //this.diseases.push(plant);
      //});

      this.loading = false;
    } catch (err) {
      this.snackbar.text = err.message;
      this.snackbar.visible = true;

      console.error(err);

      this.loading = false;
    }
  },
};
</script>