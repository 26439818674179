<template>
  <v-card text>
    <v-snackbar v-model="snackbar.visible" top right>
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.visible = false">Fechar</v-btn>
    </v-snackbar>
    <v-card-title>Adicionar Planta</v-card-title>
    <v-card-text>
      <v-row v-if="loading" justify="center" align="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
      <v-list v-if="!loading">
        <v-form ref="form" lazy-validation>
          <v-text-field v-model="plant.name" :counter="40" label="Nome" required></v-text-field>

          <v-container class="py-0">
            <v-row align="center" justify="start">
              <v-col v-for="(selection, i) in plant.diseases" :key="selection.text" class="shrink">
                <v-chip :disabled="loading" close @click:close="plant.diseases.splice(i, 1)">
                  <v-icon left v-text="selection.icon"></v-icon>
                  {{ selection.name }}
                </v-chip>
              </v-col>

              <v-col v-if="!allSelected" cols="12">
                <v-text-field
                  ref="search"
                  v-model="search"
                  full-width
                  hide-details
                  label="Pesquisar doença"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-divider v-if="!allSelected"></v-divider>

          <v-list>
            <template v-for="item in categories">
              <v-list-item
                v-if="!plant.diseases.includes(item)"
                :key="item.name"
                :disabled="loading"
                @click="plant.diseases.push(item)"
              >
                <v-list-item-avatar>
                  <v-icon :disabled="loading" v-text="item.icon"></v-icon>
                </v-list-item-avatar>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>

          <v-divider></v-divider>
        </v-form>
      </v-list>
      <v-row v-if="!loading">
        <v-spacer />
        <v-btn @click="save()" color="primary" class="mr-3">Salvar</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { db } from "@/firebase";

export default {
  data() {
    return {
      snackbar: {
        text: null,
        visible: false,
      },
      loading: false,
      diseases: [],
      plant: { diseases: [], name: "" },
      templatesQuerySnapshot: [],
      search: "",
      id:''
    };
  },
  async created() {
    try {
      this.loading = true;
      const self = this;
      this.id = this.$route.params.plant 
      if (this.$route.params.plant) {
        this.templatesQuerySnapshot = await db
          .collection("plants")
          .doc(this.$route.params.plant)
          .get()
          .then((documentSnapshot) => {
            const plant = documentSnapshot.data();
            //console.log(plant);
            this.plant = plant;
          });
      }
      this.templatesQuerySnapshot = await db
        .collection("diseases")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            const disease = doc.data();
            self.diseases.push({ name: disease.name });
            // console.log("pegou ", doc.data());
            //console.log(doc.id, " => ", doc.data());
          });
        });

      this.loading = false;
    } catch (err) {
      this.snackbar.text = err.message;
      this.snackbar.visible = true;

      console.error(err);

      this.loading = false;
    }
  },
  computed: {
    allSelected() {
      return this.plant.diseases.length === this.diseases.length;
    },
    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.diseases;

      return this.diseases.filter((item) => {
        const text = item.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.plant.diseases) {
        selections.push(selection);
      }

      return selections;
    },
  },
  watch: {
    selected() {
      this.search = "";
    },
  },
  methods: {
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.plant.diseases = [];
        this.loading = false;
      }, 2000);
    },

    async save() {
      try {
        this.loading = true;
        const planta = {
          name: this.plant.name,
          diseases: this.plant.diseases,
        };
        if (this.id) {
          //console.log("atualizandoo" , this.id);
          await db
            .collection("plants")
            .doc(this.id)
            .set(planta);
        } else {
          //console.log("criandooo");
          await db.collection("plants").add(planta);
        }
        //for (const template of this.templates) {
        //await db.collections(`plants`).set(template)
        //}

        this.snackbar.text = "Dados salvos com sucesso";
        this.snackbar.visible = true;

        this.loading = false;
      } catch (err) {
        this.snackbar.text = err.message;
        this.snackbar.visible = true;

        console.log(err);

        this.loading = false;
      }
    },
  },
};
</script>