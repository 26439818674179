<template>
  <v-card flat>
    <v-snackbar v-model="snackbar.visible" top right>
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar.visible = false">Fechar</v-btn>
    </v-snackbar>
    <v-card-title>Doenças</v-card-title>
    <v-card-text>
      <v-row v-if="loading" justify="center" align="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
      <v-list v-if="!loading">
        <v-list-group v-for="(plant, i) in diseases" :key="i" no-action>
          <template v-slot:activator>
            <v-list-item-title>
              <v-text-field
                v-model="plant.name"
                @click="editItem(plant.id)"
                readonly
                solo
                flat
                hide-details
                background-color="transparent"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-action>
              <v-btn icon @click="deleteItem(plant, diseases)">
                <v-icon color="red lighten-1">mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>

        </v-list-group>

        <v-list-item @click="addTemplate()">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content color="primary">
            <v-list-item-title>Adicionar doença</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { db } from "@/firebase";

export default {
  data() {
    return {
      snackbar: {
        text: null,
        visible: false
      },
      loading: false,
      templates: [],
      diseases: [],
      templatesQuerySnapshot: []
    };
  },
  async created() {
    try {
      this.loading = true;

      this.templatesQuerySnapshot = await db.collection("diseases").get();

      this.templatesQuerySnapshot.forEach(documentSnapshot => {
        const plant = { name: documentSnapshot.data().name, id: documentSnapshot.id };
        console.log(plant);
        this.diseases.push(plant);
      });

      this.loading = false;
    } catch (err) {
      this.snackbar.text = err.message;
      this.snackbar.visible = true;

      console.error(err);

      this.loading = false;
    }
  },
  methods: {
    editItem(disease) {
      this.$router.push({ name: "DiseaseDetails", params: { disease: disease} });
      //parent.splice(parent.indexOf(item), 1);
    },
    async deleteItem(disease, parent){
      await db.collection('diseases').doc(disease.id).delete()
      parent.splice(parent.indexOf(disease), 1);
//      console.log('tried to delete ', disease)
    },
    addProva(item) {
      if (!item.provas) {
        this.$set(item, "provas", []);
      }

      const name = `Avaliação (${item.provas.length + 1})`;
      item.provas.push({
        name
      });
      console.log(this.template);
    },
    addEtapa(item) {
      if (!item.etapas) {
        this.$set(item, "etapas", []);
      }
      const name = `Etapa (${item.etapas.length + 1})`;
      item.etapas.push({ name: name, provas: [] });
    },
    addTemplate(plantName) {
      this.$router.push({ name: "DiseaseDetails", params: { disease: plantName} });
      //this.templates.push(template);
    },
    async save() {
      try {
        this.loading = true;

        //for (const template of this.templates) {
        //await db.collections(`diseases`).set(template)
        //}

        this.snackbar.text = "Dados salvos com sucesso";
        this.snackbar.visible = true;

        this.loading = false;
      } catch (err) {
        this.snackbar.text = err.message;
        this.snackbar.visible = true;

        console.log(err);

        this.loading = false;
      }
    }
  }
};
</script>