<template>
  <v-toolbar
      color="cyan"
      max-height="60px"
      dark
    >
     <v-app-bar-nav-icon v-if="count.loggedIn"></v-app-bar-nav-icon>
  <v-toolbar-title>Calisto</v-toolbar-title>
  
      <v-spacer></v-spacer>
    <!-- Plantas -->
    <router-link to="/plants" tag="v-btn" v-if="count.loggedIn">
      <v-btn text value="plants">
        <span>Planta</span>
      </v-btn>
    </router-link>

    <!-- Doenças -->
    <router-link to="/diseases" v-if="count.loggedIn">
      <v-btn text value="diseases">
        <span>Doenças</span>
      </v-btn>
    </router-link>
        <!-- Diagnosticos  -->
    <router-link to="/diagnostic" v-if="count.loggedIn">
      <v-btn text value="diagnostic">
        <span>Diagnosticos</span>
      </v-btn>
    </router-link>
            <!-- Chat  -->
    <router-link to="/chatroomlist" v-if="count.loggedIn">
      <v-btn text value="chatroomlist">
        <span>Chat</span>
      </v-btn>
    </router-link>
    <v-btn  @click="signOut()" v-if="count.loggedIn">
      <v-icon>mdi-account</v-icon>Logout
    </v-btn>
</v-toolbar>
</template>
<script>
//import { store } from "vuex";
import { fb } from "@/firebase";
export default {
  data(){
    return{
      user:{}
    }
  },
  computed: {
    count () {
      return this.$store.getters.user
    }
  },
  methods: {
    signOut() {
      fb.auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login"
          });
        })
        .catch(error => console.log(error));
    }
  },

};
</script>