<template>
  <v-card class="mx-auto card-labs" max-width="344">
    <v-card-text>
      <p class="title-labs">Calisto</p>
    </v-card-text>
    <v-card-actions>
      <v-btn text class="primary-labs" @click="submit()">Sign in with Google</v-btn>
    </v-card-actions>
    <v-card-actions>
      <img width="200px" src />
    </v-card-actions>
  </v-card>
</template>
    

<script>
//import fb from "@/firebase";
import { fb, db } from "@/firebase";

export default {
  data() {
    return {
      error: null,
    };
  },
  //watch(){
    //count(): 
  //},
  computed: {
    count() {
      return this.$store.getters.user.loggedIn;
    },
  },
  methods: {
    isLoggedIn(logged) {
      if (logged) {
        console.log("Olha toc ", logged);
        this.$router.replace({ name: "Plants" });
      }
    },
    submit() {
      var provider = new fb.auth.GoogleAuthProvider();
      fb.auth().useDeviceLanguage();
      var self = this;
      fb.auth()
        .signInWithPopup(provider)
        .then((data) => {
          console.log("info sobre user" + data.user.uid);

          if (!self.userExists(data.user.uid)) {
            self.createUser(data.user.name, data.user.uid);
          }

          this.$router.replace({ name: "Plants" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    async userExists(uid) {
      console.log("does user exist?");
      await db
        .collection("users")
        .doc(uid)
        .get()
        .then((data) => {
          if (data.exists) {
            console.log("it does!");
            return true;
          } else {
            console.log("it does not");
            return false;
          }
        });
    },
    async createUser(name, uid) {
      console.log("criando");
      await db.collection("users").doc(uid).set({
        nome: name,
        userUid: uid,
        role: "user",
      });
      console.log("terminou de criar");
    },
  },
};
</script>
<style scoped>
.title-labs {
  color: blueviolet;
  font-weight: bold;
  font-size: large;
}
.card-labs {
  background-color: white;
  color: whitesmoke;
}
.primary-labs {
  color: aliceblue;
  font-weight: bold;
  background-color: #35adbd;
}
/*
#606060
#35adbd
#cd3b7b
#de8d2e
#9bbd4c
#419acd
#9b7c35
*/
</style>

