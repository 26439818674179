<template>
  <v-app>
    <Navbar></Navbar>
    <v-main class="labs-bg">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer>
      <span class="ml-auto overline">Cultiv.ai &copy;2020</span>
    </v-footer>
  </v-app>
</template>
<script>
import Navbar from '@/components/Navbar'
export default {
 components: {
    Navbar
  },
  
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
