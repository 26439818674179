import Vue from 'vue'
import VueRouter from 'vue-router'
import Plants from '../components/Plants.vue'
import DiseaseDetails from '../components/DiseaseDetail'
import Diseases from '../components/Diseases'
import PlantsDetail from '../components/PlantsDetail'
import Login from '../components/Login'
import Diagnostic from '../components/Diagnostic'
import Chat from '../components/Chat'
import ChatRoomList from '../views/ChatRoomList'
import { fb } from '@/firebase'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/plants',
    name: 'Plants',
    component: Plants,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/diseases',
    name: 'Diseases',
    component: Diseases,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/diseasedetails/:disease',
    name: 'DiseaseDetails',
    component: DiseaseDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plantsdetail/:plant',
    name: 'PlantsDetail',
    component: PlantsDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/diagnostic',
    name: 'Diagnostic',
    component: Diagnostic,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat/:id',
    name: 'Chat',
    component: Chat,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chatroomlist',
    name: 'ChatRoomList',
    component: ChatRoomList,
    meta: {
      requiresAuth: true
    }
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await fb.getCurrentUser()){
    next('/');
  }else{
    next();
  }
});

export default router
