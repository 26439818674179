<template>
<v-card
    max-width="450"
    class="mx-auto"
  >
    <v-list three-line>
      <h3 v-if="chatrooms.length == 0">
        Não existem chats no momento
      </h3>
      <template v-for="(item, index) in chatrooms">

        <!--v-divider
          v-if="chatroom.lenght > 1"
          :key="index"
          :inset="item.inset"
        ></v-divider-->

        <v-list-item
          :key="index"
          @click="openChat(item.chatId)"
          :class="{disabledChat: (item.diagnostic.status == 'closed')?true:false}"
        >
          <v-list-item-avatar >
            <v-img :src="item.diagnostic.url"></v-img>
          </v-list-item-avatar>

          <v-list-item-content >
            <v-list-item-title v-html="item.user.nome"></v-list-item-title>
            <v-list-item-subtitle v-html="item.diagnostic.classificacao"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-if="item.diagnostic.status == 'closed'" style="color: gray">
            Desabilitado
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import { db } from "@/firebase";
export default {
  data() {
    return {
      chatrooms: [],
      chatroomids: [],
    };
  },
  methods: {
    openChat(chatId){
        this.$router.push({ name: "Chat", params: { id: chatId} });
    }
  },
  async created() {
    try {
      this.loading = true;

      await db
        .collection("messages")
        //.where("to", "==", "")
        .get()
        .then((documents) => {
          documents.docs.forEach((chat) => {
            const ele = chat.data();
            ele["chatId"] = chat.id;
            //console.log('olha eleee', ele)
            this.chatroomids.push(ele)
            //this.diagnostics.push(ele);
          });
        });
      for (var chat of this.chatroomids) {
        const chatroom = {chatId: chat.chatId};

        await db
          .collection("users")
          .doc(chat.from)
          .get()
          .then((document) => {
              //console.log('olha quem é', document.data())
            chatroom['user'] = document.data()
          });

          await db
          .collection("diagnostic")
          .doc(chat.diagnostic)
          .get()
          .then((document) => {
            //console.log('olha o que foi', document.data())
            if(document.exists){
              chatroom['diagnostic'] = document.data()

            }
            
          });
          //console.log('olha a porra toda', chatroom)
          if(chatroom.diagnostic){
            this.chatrooms.push(chatroom)
          }
      }
      console.log('salaaas', this.chatrooms)
      this.loading = false;
    } catch (err) {
      console.error(err);

      this.loading = false;
    }
  },
};
</script>
<style scoped>
.disabledChat{
  color: rgb(92, 88, 88);
  background-color:rgb(199, 194, 194);
}
</style>